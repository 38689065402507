/**
 * Плагин вывода и фильтрации регионов
 * @param {object[]} citiesArray - принимает массив объектов городов ({name, id, region_id, slug, link})
 * @param {object[]} regionsArray - принимает массив объектов регионов ({name, id, slug, link})
 * @param {string} cityLinkTemplate - шаблон ссылки для города
 * @param {string} regionLinkTemplate - шаблон ссылки для региона
 * @returns {$}
 */
$.fn.regionsFilter = function (citiesArray, regionsArray, cityLinkTemplate, regionLinkTemplate) {
    var $self = $(this);
    var $input = $('.js-search-regions');
    var autocompleteArray = citiesArray.concat(regionsArray);

    var listData = autocompleteArray;

    /**
     * Получить имя региона по ID региона
     * @param id - ID региона в массиве регионов
     * @returns {string}
     */
    var getRegionNameById = function (id) {
        return listData.filter(function (item) {
            return !item.hasOwnProperty('region_id') && item.id === id;
        })[0].name;
    };

    /**
     * Получить массив уникальных первых букв названий регионов
     * @returns {string[]}
     */
    var getRegionLetterArray = function () {
        return listData.filter(function (item) {
            return !item.hasOwnProperty('region_id') && item.hasOwnProperty('unsorted') && !item.unsorted;
        }).sort(function (a, b) {
            return a.name.localeCompare(b.name);
        }).map(function (region) {
            return region.name.charAt(0).toUpperCase();
        }).filter(function (item, index, array) {
            return array.indexOf(item) === index;
        });
    };

    /**
     * Получить массив названий регионов, начинающихся с опредленной буквы
     * @param letter
     * @returns {object[]}
     */
    var getRegionsByFirstLetter = function (letter) {
        return listData.filter(function (item) {
            return !item.hasOwnProperty('region_id') && item.hasOwnProperty('unsorted') && !item.unsorted;
        }).filter(function (region) {
            return region.name.charAt(0).toUpperCase() === letter;
        });
    };

    /**
     * Получить массив отфильтрованных городов для указанного региона
     * @param {string} id - ID региона
     * @returns {object[]}
     */
    var getFilteredCitiesByRegionId = function (id) {
        return listData.filter(function (item) {
            return item.hasOwnProperty('region_id');
        }).filter(function (city) {
            return city.region_id === id;
        });
    };

    /**
     * Получить массив всех городов для указанного региона
     * @param {string} id - ID региона
     * @returns {object[]}
     */
    var getAllCitiesByRegionId = function (id) {
        return citiesArray.filter(function (city) {
            return city.region_id === id;
        });
    };

    /**
     * Сгенерировать html для списка городов из массива
     * @param {object[]} cityArray - массив городов
     * @returns {string}
     */
    var getCityListMarkup = function (cityArray) {
        if (cityArray.length) {
            var $cityList = $('<ul class="city-list" />');
            cityArray.forEach(function (city) {
                var link = city.link ? city.link : cityLinkTemplate.replace('{slug}', city.slug);
                $('<li>', {
                    class: 'city-list-item',
                    append: $('<a>', {
                        href: link,
                        text: city.name
                    })
                }).appendTo($cityList);
            });
            return $cityList;
        } else {
            return '';
        }
    };

    /**
     * Сгенерировать html для списка регионов
     * @returns {string}
     */
    var getRegionListMarkup = function () {
        var $regionListPanel = $('<div>').addClass('regions__list-panel');
        var unsortedRegions = listData.filter(function (item) {
            return !item.hasOwnProperty('region_id') && item.hasOwnProperty('unsorted') && item.unsorted;
        });

        getRegionLetterArray().forEach(function (letter) {
            var $regionList = $('<ul>', {
                class: 'regions__list',
                attr: {
                    'data-letter': letter
                }
            });
            getRegionsByFirstLetter(letter).forEach(function (region) {
                var regionCitiesFiltered = getFilteredCitiesByRegionId(region.id);
                var regionCitiesAll = getAllCitiesByRegionId(region.id);
                var link = region.link ? region.link : regionLinkTemplate.replace('{slug}', region.slug);

                var $regionListItem = $('<li>', {
                    class: 'regions__list-item js-region-item',
                    append: $('<span>', {
                        class: 'region-name js-region-name',
                        append: function () {
                            if (regionCitiesFiltered.length || regionCitiesAll.length) {
                                return $('<div>').text(region.name)
                                    .append($('<span>', {
                                        class: 'amount',
                                        text: (regionCitiesFiltered.length || regionCitiesAll.length)
                                    }))
                                    .html();
                            } else {
                                return $('<a>', {
                                    href: link,
                                    text: region.name
                                });
                            }
                        }
                    }).add(getCityListMarkup(regionCitiesFiltered.length ? regionCitiesFiltered : regionCitiesAll))
                });

                if ($input.val().toLowerCase() === region.name.toLowerCase()) {
                    $regionListItem.addClass('active');
                }

                $regionListItem.appendTo($regionList);
            });
            $regionList.appendTo($regionListPanel);
        });

        // !!Другой регион
        if (unsortedRegions.length) {
            var $unsortedRegion = $('<ul>', {
                class: 'regions__list',
                attr: {
                    'data-letter': ''
                }
            });
            unsortedRegions.forEach(function (region) {
                var regionCitiesFiltered = getFilteredCitiesByRegionId(region.id);
                var regionCitiesAll = getAllCitiesByRegionId(region.id);
                var link = region.link ? region.link : regionLinkTemplate.replace('{slug}', region.slug);

                var $regionListItem = $('<li>', {
                    class: 'regions__list-item js-region-item',
                    append: $('<span>', {
                        class: 'region-name js-region-name',
                        append: function () {
                            if (regionCitiesFiltered.length || regionCitiesAll.length) {
                                return $('<div>').text(region.name)
                                    .append($('<span>', {
                                        class: 'amount',
                                        text: (regionCitiesFiltered.length || regionCitiesAll.length)
                                    }))
                                    .html();
                            } else {
                                return $('<a>', {
                                    href: link,
                                    text: region.name
                                });
                            }
                        }
                    }).add(getCityListMarkup(regionCitiesFiltered.length ? regionCitiesFiltered : regionCitiesAll))
                });

                if ($input.val().toLowerCase() === region.name.toLowerCase()) {
                    $regionListItem.addClass('active');
                }

                $regionListItem.appendTo($unsortedRegion);
            });
            $unsortedRegion.appendTo($regionListPanel);
        }
        // !!Другой регион

        $regionListPanel.data('scrollbar', new PerfectScrollbar($regionListPanel.get(0)));
        setTimeout(function () {
            // $regionListPanel.data('scrollbar').update();
        }, 50);

        return $regionListPanel;
    };

    /**
     * Обновить блок в соответствии с переданным массивом городов
     * @param {object[]} newListData - массив городов
     */
    var update = function (newListData) {
        listData = newListData;
        if ($input.val() && !listData.length) {
            $self.empty().append($('<div>').addClass('easy-autocomplete-helper')
                .append('<p>Мы ничего не нашли по этому запросу.</p>')
                .append('<p>Телефон службы поддержки <a href="tel:+74959212288">+7 (495) 921-22-88</a></p>'));
        } else {
            $self.empty().append(getRegionListMarkup());
        }
    };

    /**
     * Проверить поле поиска на наличие значения
     */
    var checkValue = function () {
        var $autocompleteContainer = $('.easy-autocomplete');
        if ($input.val()) {
            $autocompleteContainer.addClass('has-value');

            if (!$autocompleteContainer.find('.easy-autocomplete-clear').length) {
                $autocompleteContainer.prepend($('<button>', {
                    type: 'button',
                    class: 'easy-autocomplete-clear',
                    on: {
                        click: function () {
                            $(this).remove();
                            $input.val('').trigger('change').focus();
                            return false;
                        }
                    }
                }));
            }
        } else {
            $autocompleteContainer.removeClass('has-value');
        }
    };

    /**
     * Показать сообщение "ничего не найдено"
     */
    var dropdownCheck = function () {
        var $dropdown = $('.easy-autocomplete-container');
        $dropdown.find('.easy-autocomplete-helper').remove();
        if ($input.val() && !listData.length) {
            $('<div>').addClass('easy-autocomplete-helper')
                .append('<p>Мы ничего не нашли по этому запросу.</p>')
                .append('<p>Телефон службы поддержки <a href="tel:+74959212288">+7 (495) 921-22-88</a></p>')
                .appendTo($dropdown);
        }
    };

    /**
     * Показать панель фильтра
     */
    var panelOpen = function () {
        $('.js-regions-toggle').addClass('active');
        $('.js-regions').addClass('active');
        $input.focus();
        setTimeout(function () {
            var $regionListPanel = $('.js-regions-wrapper .ps');
            if ($regionListPanel.length) {
                $regionListPanel.data('scrollbar').update();
            }
        }, 100);
    };

    /**
     * Скрыть панель фильтра
     */
    var panelClose = function () {
        $('.js-regions-toggle').removeClass('active');
        $('.js-regions').removeClass('active');
    };

    /**
     * Инициализация плагина
     */
    var init = function () {
        update(listData);

        $('.js-regions-toggle').on('click', function () {
            if ($(this).hasClass('active')) {
                panelClose();
            } else {
                panelOpen();
            }
        });

        $('.js-regions-close').on('click', function () {
            panelClose();
        });

        $self.on('click', '.js-region-name', function () {
            var $t = $(this);
            $self.find('.js-region-item').removeClass('active').filter($t.closest('.js-region-item')).addClass('active');
        });

        $(document).on('click', function (e) {
            if (
                !$(e.target).closest('.js-regions').length
                && !$(e.target).closest('.js-regions-toggle').length
                && !$(e.target).closest('.easy-autocomplete').length
            ) {
                panelClose();
            }
        });

        $(document).on('mousedown', '.eac-item', function () {
            var item = $input.getSelectedItemData();
            $input.val(item.name);

            if (!item.hasOwnProperty('region_id') && getAllCitiesByRegionId(item.id).length) {
                // is a region with cities in it
            } else {
                // is either a city or a region without cities in it
                window.location.href = item.link ? item.link : regionLinkTemplate.replace('{slug}', item.slug);
            }

            setTimeout(function () {
                $input.blur();
            }, 300);

        });

        $(document).on('keyup', function (e) {
            if (e.key === 'Escape') {
                panelClose();
            }
        });

        $input
            .easyAutocomplete({
                data: autocompleteArray,
                getValue: 'name',
                list: {
                    match: {
                        enabled: true
                    },
                    onShowListEvent: function () {
                        var $acList = $('.easy-autocomplete-container ul');
                        if ($acList.length) {
                            if (typeof $acList.data('scrollbar') === 'undefined') {
                                $acList.data('scrollbar', new PerfectScrollbar($acList.get(0)));
                            }
                            $acList.data('scrollbar').update();
                        }
                        $input.trigger('change');
                    },
                    onKeyEnterEvent: function () {
                        setTimeout(function () {
                            $input.blur();
                        }, 300);
                    },
                    onSelectItemEvent: function () {
                        $input.trigger('change');
                    },
                    onChooseEvent: function () {
                        var item = $input.getSelectedItemData();
                        $input.val(item.name);

                        if (!item.hasOwnProperty('region_id') && getAllCitiesByRegionId(item.id).length) {
                            // is a region with cities in it
                        } else {
                            // is either a city or a region without cities in it
                            window.location.href = item.link ? item.link : regionLinkTemplate.replace('{slug}', item.slug);
                        }

                        setTimeout(function () {
                            $input.blur();
                        }, 300);
                    }
                },
                template: {
                    type: 'custom',
                    method: function (value, item) {
                        return value + (item.hasOwnProperty('region_id') ? '<span>' + getRegionNameById(item.region_id) + '</span>' : '');
                    }
                }
            })
            .on('input change', function () {
                var value = $(this).val().toLowerCase();
                var $regionsFilter = $('.js-regions-wrapper');
                var filteredCities = citiesArray.filter(function (city) {
                    return city.name.toLowerCase().indexOf(value) !== -1;
                });
                var filteredRegions = regionsArray.filter(function (region) {
                    var isRegionNameMatched = region.name.toLowerCase().indexOf(value) !== -1;
                    var isRegionContainsMatchedCities = filteredCities.map(function (city) {
                        return city.region_id;
                    }).indexOf(region.id) !== -1;

                    return isRegionNameMatched || isRegionContainsMatchedCities;
                });
                var filteredData = filteredCities.concat(filteredRegions);
                if (typeof $regionsFilter.data('regionsFilter') !== 'undefined') {
                    $regionsFilter.data('regionsFilter').update(filteredData);
                }

                checkValue();
                dropdownCheck();
            })
            .on('focus', function () {
                $('.easy-autocomplete').addClass('active');
                checkValue();
            })
            .on('blur', function () {
                $('.easy-autocomplete').removeClass('active');
            });
    };

    init();

    this.update = update;

    return this;
};
